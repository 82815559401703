/**
 * Created by osirvent on 01/03/2016.
 */
angular
    .module('annexaApp')
    .factory('SignLocalFactory',['$document', '$window', '$rootScope', 'Language', 'SignModals', 'AnnexaModalFactory', '$q', 'RestService', '$filter', '$http','GlobalDataFactory', 'AnnexaFormlyFactory', 'CacheFactory', function ($document, $window, $rootScope, Language, SignModals, AnnexaModalFactory, $q, RestService, $filter, $http, GlobalDataFactory, AnnexaFormlyFactory, CacheFactory) {
        var factory = {};

        factory.signDocument = function(document_url, alias, signature, key, sizes) {
            if(!factory.processingSignature) {
                factory.processingSignature = { url: document_url, alias: alias, signature: signature, key: key, sizes: sizes };
                runSignature();
            } else {
                factory.pendingSignatures.push({ url: document_url, alias: alias, signature: signature, key: key, sizes: sizes });
            }
        }

        factory.sendToSignModal = function(documentIds, documents, dossierReceivers) {
            var sendToSign = function() {
                var validationOk = true;

                var self = this;

                var model = this.data.result;

                if(model.urgent && !model.urgentReason) {
                    this.alerts.push({ msg: 'global.documents.modal.sendToSign.errorUrgent' });
                    validationOk = false;
                } else if(model.circuit.type == 'PRESET' && !model.circuit.selected) {
                    this.alerts.push({ msg: 'global.documents.modal.sendToSign.errorPresetSelected' });
                    validationOk = false;
				} else if(model.circuit.type == 'CUSTOM' && model.circuit.custom.length == 0) {
                    this.alerts.push({ msg: 'global.documents.modal.sendToSign.errorCustomEmpty' });
                    validationOk = false;
                } else if(model.circuit.alertProfiles && model.circuit.profiles && model.circuit.profiles.length == 0) {
                	this.alerts.push({ msg: 'global.documents.modal.sendToSign.errorAlertProfiles' });
                    validationOk = false;
                } else if(model.circuit.type == 'CUSTOM') {
                    _.forEach(model.circuit.custom, function (item) {
                    	if(!item.paralelConditionedAction && item.signActionType.code == 'THIRDSIGN') {
                    		if (!item.third || item.third.id == -1) {
                                self.alerts.push({msg: $filter('translate')('global.documents.modal.sendToSign.errorCustomThird', {order: item.actionOrder})});
                                validationOk = false;
                            }
                    		if (!item.thirdAddress || item.thirdAddress.id == -1) {
                                self.alerts.push({msg: $filter('translate')('global.documents.modal.sendToSign.errorCustomThirdAddress', {order: item.actionOrder})});
                                validationOk = false;
                            }
                    	} else if(!item.paralelConditionedAction && item.signActionType.code == 'AUTOSIGN_ORGAN') {
                    		if(!item.diligenceStampType || !item.diligenceStampType.id || item.diligenceStampType.id == -1) {
                    			self.alerts.push({msg: $filter('translate')('global.documents.modal.sendToSign.errorCustomSignAutoSignType', {order: item.actionOrder})});
                                validationOk = false;
                    		}
                    		if((!item.diligenceStampType.canChange && (!item.subjectAux || item.subjectAux == '')) || (item.diligenceStampType.canChange && (!item.subject || item.subject == ''))) {
                    			self.alerts.push({msg: $filter('translate')('global.documents.modal.sendToSign.errorCustomSignAutoSignText', {order: item.actionOrder})});
                                validationOk = false;
                    		}
                    	} else if(item.paralelConditionedAction) {
                    		if(!item.paralelSignAction || item.paralelSignAction.length == 0) {
                    			self.alerts.push({msg: $filter('translate')('global.documents.modal.sendToSign.errorConditionedSignNoAction', {order: item.actionOrder})});
                                validationOk = false;
                    		}
                    	} else {
                    		if (!item.user || item.user.id == -1 || !item.user.user || item.user.user.id == -1) {
                                self.alerts.push({msg: $filter('translate')('global.documents.modal.sendToSign.errorCustomUser', {order: item.actionOrder})});
                                validationOk = false;
                            }
                    	}

                        if (!item.paralelConditionedAction && !item.signActionType) {
                            self.alerts.push({msg: $filter('translate')('global.documents.modal.sendToSign.errorCustomSignActionType', {order: item.actionOrder})});
                            validationOk = false;
                        }else if(model.circuit.signatureWithoutOrder && !item.signActionType.signatureWithoutOrder){
							self.alerts.push({msg: $filter('translate')('global.documents.modal.sendToSign.errorCustomSignActionTypeWithoutOrder', {order: item.actionOrder})});
                            validationOk = false;
						}
                    });
                } else if (model.circuit.type == 'CUSTOM' && model.circuit.addToFavorite && !model.circuit.favoriteName) {
                    this.alerts.push({ msg: 'global.documents.modal.sendToSign.errorCustomFavorite' });
                    validationOk = false;
                } else if (model.circuit.edit && !model.circuit.selected.language1 && model.circuit.selected.language1 == '') {
                    this.alerts.push({ msg: 'global.documents.modal.sendToSign.errorCustomFavorite' });
                    validationOk = false;
                } 

                if(validationOk) {
                    var signProcessActions = [];

                    if(model.circuit.type == 'PRESET') {
                        _.forEach(model.circuit.selected.presetSignActions, function(item) {
                        	if(item.signActionType.code == 'AUTOSIGN_ORGAN' && item.diligenceStampType) {
                        		if(item.diligenceStampType.canChange) {
                        			item.customText = item.subject;
                        		} else {
                        			item.customText = item.subjectAux;
                        		}
                        	}
                            signProcessActions.push({
                                actionOrder: item.actionOrder,
                                maxDurationDays: item.maxDurationDays,
                                presetSignAction: { id: item.id },
                                signActionType: { id: item.signActionType.id },
                                signatoryType: item.signatoryType ? { id: item.signatoryType.id } : undefined,
                                userMustSign: item.user ? { id: item.user.id } : undefined,
                                third: item.third ? { id: item.third.id } : undefined,
                                thirdAddress: item.thirdAddress ? { id: item.thirdAddress.id } : undefined,
                                customText: item.customText ? item.customText : undefined,
                                diligenceStampType: item.diligenceStampType ? { id: item.diligenceStampType.id }: undefined,
                                paralelConditionedAction: item.paralelConditionedAction,
                                paralelSignAction: item.paralelSignAction,
                                onlyOneSign: item.onlyOneSign
                            })
                        });
                    } else if(model.circuit.type == 'CUSTOM') {
                        _.forEach(model.circuit.custom, function(item) {
                        	var spa = {
                                    actionOrder: item.actionOrder,
                                    maxDurationDays: item.maxDurationDays,
                                    signActionType: item.signActionType ? { id: item.signActionType.id } : undefined,
                                    signatoryType: { id: $rootScope.app.configuration.signatory_type_user.value },
                                    paralelConditionedAction: item.paralelConditionedAction,
                                    onlyOneSign: item.onlyOneSign
                                };
                        	if(!item.paralelConditionedAction && item.signActionType.code == 'THIRDSIGN'){
                        		spa.third = { id: item.third.id };
                        		spa.thirdAddress = { id: item.thirdAddress.id };
                        	} else if(!item.paralelConditionedAction && item.signActionType.code == 'AUTOSIGN_ORGAN'){
                        		if(item.diligenceStampType.canChange) {
                        			spa.customText = item.subject;
                        		} else {
                        			spa.customText = item.subjectAux;
                        		}
                        		spa.diligenceStampType = { id: item.diligenceStampType.id };
                        	} else if(item.paralelConditionedAction) {
                        		spa.paralelSignAction = item.paralelSignAction;
                        	} else {
                        		spa.userMustSign = { id: item.user.id };
                        	}
                            signProcessActions.push(spa);
                        });
                    }
                    
                    var signProcessAlertProfiles = [];
                    if(model.circuit.type == 'CUSTOM' && model.circuit.alertProfiles && model.circuit.profiles.length > 0) {
                    	_.forEach(model.circuit.profiles, function(prof) {
                    		var spap = {
                    				profile: { id: prof.id }
                    		}
                        	signProcessAlertProfiles.push(spap);
                    	});
                    } else if(model.circuit.type == 'PRESET' && model.circuit.selected.sendAlertProfile && model.circuit.selected.presetSignCircuitAlertProfiles.length > 0) {
                    	_.forEach(model.circuit.selected.presetSignCircuitAlertProfiles, function(prof) {
                    		var spap = {
                    				profile: { id: prof.profile.id }
                    		}
                        	signProcessAlertProfiles.push(spap);
                    	});
                    }

                    var signProcesses = [];

                    _.forEach(this.data.documents, function (item) {
                        var signProcess = {
                            document: { id: item.id },
                            presetSignCircuit: model.circuit.type == 'PRESET' ? { id: model.circuit.selected.id } : undefined,
                            comments: model.observations,
                            urgent: model.urgent,
                            urgentReason: ((model.urgentReason)?((model.urgentReason.replaceAll)?model.urgentReason.replaceAll('"','\''):model.urgentReason.replace(/"/g,'\'')):model.urgentReason),
                            favoriteName: model.circuit.type == 'CUSTOM' && model.circuit.addToFavorite ? model.circuit.favoriteName : undefined,
                            signProcessActions: signProcessActions,
                            editCircuit: model.circuit.edit ? model.circuit.selected.id : undefined,
                            editFavoriteName: model.circuit.edit && model.circuit.selected.language1 != '' ? model.circuit.selected.language1 : undefined,
							signatureWithoutOrder: model.circuit.type == 'PRESET' ? model.circuit.selected.signatureWithoutOrder : model.circuit.signatureWithoutOrder,
							sendAlertProfile: model.circuit.type == 'PRESET' ? model.circuit.selected.sendAlertProfile : model.circuit.alertProfiles,
							signProcessAlertProfiles: signProcessAlertProfiles
                        };

                        signProcesses.push(signProcess);
                    });

                    factory.sendToSign(signProcesses)
                        .then(function(data) {
                            self.close();
                            var document = JSOG.decode(data);
                            if(document && document.length > 0 && model.circuit && model.circuit.addToFavorite && $rootScope.LoggedUser){
                                _.forEach(document, function(doc) {
                                        if (!$rootScope.LoggedUser.presetSignCircuits) {
                                            $rootScope.LoggedUser.presetSignCircuits = [];
                                        }
                                        if (!$linq($rootScope.LoggedUser.presetSignCircuits).contains(doc.presetSignCircuit, function(x, y){if(x.id == y.id){return true;}return false;})) {
                                            $rootScope.LoggedUser.presetSignCircuits.push(doc.presetSignCircuit);
                                        }
                                    }
                                );
                            }
                            if(document && document.length > 0 && model.circuit && model.circuit.edit && $rootScope.LoggedUser){
                                _.forEach(document, function(doc) {
		                            	var index = $linq($rootScope.LoggedUser.presetSignCircuits).indexOf("x => x.id == " + doc.presetSignCircuit.id);
			                            if (index > -1) {
			                            	$rootScope.LoggedUser.presetSignCircuits[index] = doc.presetSignCircuit;
		                            	}
                                    }
                                );
                            }
                            if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('LoggedUserData')) {
                            	var cache = CacheFactory.get('globalDataCache').get('LoggedUserData');
                                cache.userObject.presetSignCircuits = $rootScope.LoggedUser.presetSignCircuits;
                                CacheFactory.get('globalDataCache').remove('LoggedUserData');
                                CacheFactory.get('globalDataCache').put('LoggedUserData', cache);
                            }
                            if(GlobalDataFactory.presetSignCircuit){
                            	GlobalDataFactory.presetSignCircuit.length = 0;
                            }else{
                            	GlobalDataFactory.presetSignCircuit = [];
                            }
                            if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('./api/general/findAll?object_type=PresetSignCircuit')){
                            	CacheFactory.get('globalDataCache').remove('./api/general/findAll?object_type=PresetSignCircuit');
                            }
                            GlobalDataFactory.loadGlobalData();
                            $rootScope.$broadcast('SendToSignModalFinished', { data: JSOG.decode(data)});
                        }).catch(function(error) {
                            if(error.data.message == "Unknown Host") {
                                self.alerts.push({msg: $filter('translate')('global.errors.unknowHostError')+(($rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.sign_util_configuration && $rootScope.app.configuration.sign_util_configuration.timeStampUrl)?$rootScope.app.configuration.sign_util_configuration.timeStampUrl:'')});
                            }else if(error.data.message == "Error document can't convert to PDF"){
                                self.alerts.push({msg: $filter('translate')('global.errors.cantConvertToPDF')});
                            }else if(error.data.message == "Error document protected PDF"){
                                self.alerts.push({msg: $filter('translate')('global.errors.protectedPDF')});
                            }else if(error.data.message == "Error document in book and circuit is not correct"){
                                self.alerts.push({msg: $filter('translate')('global.errors.documentBookAndCircuitError')});
                            }else if(error.data.message == "Error book state modify"){
                                self.alerts.push({msg: $filter('translate')('global.errors.errorModifyBookState')});
                            }else if(error.data.message == "There is already a signing process"){
                                self.alerts.push({msg: $filter('translate')('global.errors.errorAlreadySigningProcess')});    
                            }else if(error.data.message == "Error document in decree and circuit is not correct"){
                                self.alerts.push({msg: $filter('translate')('global.errors.documentDecreeAndCircuitError')});
                            }else if(error.data.message == "Error decre state modify"){
                                self.alerts.push({msg: $filter('translate')('global.errors.documentDecreeAndCircuitError')});
                            }else if(error.data.message == "Is not valid document from template not found"){
                                self.alerts.push({msg: $filter('translate')('global.errors.documentNotValidFromTemplateError')});
                            }else if(error.data.message == "Error no action possible"){
                                self.alerts.push({msg: $filter('translate')('global.documents.modal.sendToSign.errorNoActionPossibleToSign')});
                            }else if(error.data.message == "Is not valid proposal document"){
                                self.alerts.push({msg: $filter('translate')('global.errors.proposalDocumentDocx')});
                            }else{
                                self.alerts.push({msg: $filter('translate')('global.documents.modal.sendToSign.errorSendToSign')});
                            }
                    });

                }
            }

            var modal = angular.copy(SignModals.sendToSign);
            modal.data = { documentIds: documentIds, documents: documents, result: undefined, dossierReceivers: dossierReceivers };
            modal.submitLabel = 'global.literals.sendToSignValidate';
            modal.submitFunction = sendToSign;

            AnnexaModalFactory.showModal('modalSendToSign', modal);

        }

        factory.sendToSign = function (signProcesses) {
            var deferred = $q.defer();

            $http({
                url: './api/sign/document',
                method: 'POST',
                data: signProcesses
            }).then(function (data) {
                if(data.status == 200) {
                    deferred.resolve(data.data);
                } else {
                    deferred.reject(data);
                }
            }).catch(function(error) {
                deferred.reject(error);
            })

            return deferred.promise;
        }

        factory.getSignActionTypes = function() {
        	var deferred = $q.defer();
           	deferred.resolve(GlobalDataFactory.signActionTypes);
            return deferred.promise;
        }
        
        factory.deleteSignCircuit = function(idSignCircuit) {
        	var deferred = $q.defer();

            $http({
                url: './api/sign/signCircuit/' + idSignCircuit,
                method: 'DELETE'
            }).then(function () {
                deferred.resolve();
            }).catch(function(error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        factory.createDiligenceStamp = function(documents){
        	$http({
                url: './api/sign/limitsConfigCommentSign',
                method: 'GET'
            }).then(function (data) {
        		var modal = angular.copy(SignModals.createDiligenceStampWizard);
        		_.forEach(modal.wizard.steps, function (step, key) {
                    modal.wizard.steps[key].annexaFormly.model = {};
                    modal.wizard.steps[key].annexaFormly.options = {};
                });
        		modal.wizard.modal = modal;
                modal.wizard.documents = documents;
                modal.wizard.modal.canFirstClose = true;
                
                if(data && data.data) {
                	var subjectField = $linq(modal.wizard.steps[0].annexaFormly.fields).firstOrDefault(undefined, "x => x.key == 'subject'");
                	if(data.data[1] > 0) {
                		subjectField.templateOptions.limitLength = data.data[1];
                	}
                	if(data.data[0] > 0) {
                		subjectField.templateOptions.maxLines = data.data[0];
                	}
                }
                
                var createDiligenceStampFunction = function(){
                	var self = this;
                	var documents = this.wizard.documents;
                	if(documents && documents.length > 0){
                        var createDiligenceStampModel = {
                            documents: $linq(documents).select("x => x.id").toArray(),
                            diligenceStampType: this.wizard.steps[0].annexaFormly.model.type.id,
                            subject: this.wizard.steps[0].annexaFormly.model.subject
                        }
                        if(createDiligenceStampModel && createDiligenceStampModel.diligenceStampType && this.wizard.steps[0].annexaFormly.model.type.certificateType != 'ORGAN'){
                        	createDiligenceStampModel.serverCertificate = ((this.wizard.steps[1].annexaFormly.model.serverCertificate && this.wizard.steps[1].annexaFormly.model.serverCertificate.id)?this.wizard.steps[1].annexaFormly.model.serverCertificate.id:undefined);
                        	createDiligenceStampModel.pinServerCertificate = this.wizard.steps[2].annexaFormly.model.pinServerCertificate;
                        }
                        factory.sendToCreateDiligenceStamp(createDiligenceStampModel).then(function(data) {
                            self.close();
                            $rootScope.$broadcast('SendToSignModalFinished', { data: JSOG.decode(data)});
                        }).catch(function(error) {
                            if(error.data.message == "Unknown Host") {
                                self.alerts.push({msg: $filter('translate')('global.errors.unknowHostError')+(($rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.sign_util_configuration && $rootScope.app.configuration.sign_util_configuration.timeStampUrl)?$rootScope.app.configuration.sign_util_configuration.timeStampUrl:'')});
                            }else if(error.data.message == "Error document can't convert to PDF"){
                                self.alerts.push({msg: $filter('translate')('global.errors.cantConvertToPDF')});
                            }else if(error.data.message == "Error document protected PDF"){
                                self.alerts.push({msg: $filter('translate')('global.errors.protectedPDF')});
                            }else if(error.data.message == "Error document in book and circuit is not correct"){
                                self.alerts.push({msg: $filter('translate')('global.errors.documentBookAndCircuitError')});
                            }else if(error.data.message == "Error book state modify"){
                                self.alerts.push({msg: $filter('translate')('global.errors.errorModifyBookState')});
                            }else if(error.data.message == "There is already a signing process"){
                                self.alerts.push({msg: $filter('translate')('global.errors.errorAlreadySigningProcess')});    
                            }else if(error.data.message == "Error document in decree and circuit is not correct"){
                                self.alerts.push({msg: $filter('translate')('global.errors.documentDecreeAndCircuitError')});
                            }else if(error.data.message == "Error decre state modify"){
                                self.alerts.push({msg: $filter('translate')('global.errors.documentDecreeAndCircuitError')});
                            }else{
                                self.alerts.push({msg: $filter('translate')('global.documents.modal.sendToSign.errorSendToSign')});
                            }
                        });
               	 	}else{
               	 		self.close();
               	 	}
                };
                AnnexaFormlyFactory.showModal('createDiligenceStampModal', modal, createDiligenceStampFunction, false, true);
            }).catch(function(error) {
            	
            })
        }
        
        factory.sendToCreateDiligenceStamp = function (createDiligenceStamp) {
            var deferred = $q.defer();

            $http({
                url: './api/sign/createDiligenceStamp',
                method: 'POST',
                data: createDiligenceStamp
            }).then(function (data) {
                if(data.status == 200) {
                    deferred.resolve(data.data);
                } else {
                    deferred.reject(data);
                }
            }).catch(function(error) {
                deferred.reject(error);
            })

            return deferred.promise;
        }
        
        factory.getMaxLenghtCommentSign = function () {
            var deferred = $q.defer();

            $http({
                url: './api/sign/maxLenghtCommentSign',
                method: 'GET'
            }).then(function (data) {
                if(data.status == 200) {
                    deferred.resolve(data.data);
                } else {
                    deferred.reject(data);
                }
            }).catch(function(error) {
                deferred.reject(error);
            })

            return deferred.promise;
        }
        
        factory.getAllUsersPresetSignCircuits = function () {
            var deferred = $q.defer();

            $http({
                url: './api/presetsigncircuits/getAllUsersPresetSignCircuits',
                method: 'GET'
            }).then(function (data) {
                if(data.status == 200) {
                    deferred.resolve(data.data);
                } else {
                    deferred.reject(data);
                }
            }).catch(function(error) {
                deferred.reject(error);
            })

            return deferred.promise;
        }

        return factory;
    }]).directive('maxlines', function() {
    	return {
    		restrict: 'A',
    		require: 'ngModel',
    		link: function(scope, elem, attrs, ngModel) {
    			var maxLines = 0;
    			var maxWidth = 0;
    			attrs.$observe('maxlines', function(val) {
    				if (val) {
    					maxLines = parseInt(val);
    					addKeypress();
    		        } else {
    		        	removeKeypress();
    		        }
    			});
    			
    			attrs.$observe('limitlength', function(val) {
    				if (val) {
    					maxWidth = getTextWidth("O".repeat(parseInt(val)), "5pt arial");
    				}
    			});
    			ngModel.$validators.maxlines = function(modelValue, viewValue) {
    				if(maxLines > 0) {
						var numLines = (viewValue || '').split("\n").length;
        				return numLines <= maxLines;
    				}
    				return true;
    			};
    			ngModel.$validators.limitlength = function(key, viewValue) {
    				if(maxWidth > 0) {
    					var canAddChar = getTextWidth(viewValue, "5pt arial") < maxWidth;
						if((viewValue || '').split("\n").length > 1 && canAddChar) {
							return canAddEnter(viewValue);
						}
						return canAddChar;
    				}
    				return true; 
    			};
    			
    			var canAddEnter = function(viewValue) {
    				if(maxWidth > 0) {
						var maxWidthPerLine = maxWidth/maxLines;
						var canAddEnter = true;
						var totalLines = 0;
						_.forEach((viewValue || '').split("\n"), function (text) {
							if(text != '') {
    							var lines = Math.ceil(getTextWidth(text, "5pt arial")/maxWidthPerLine);
    							totalLines = totalLines + lines;
    							if(lines >= maxLines || totalLines > maxLines) {
    								canAddEnter = false;
    							}
							}
                        });
						return canAddEnter;
					}
    			}

    			function addKeypress() {
    				elem.on('keypress', function(event) {
    					var oldVal = elem.val();
    					setTimeout(function() {
    						if (event.keyCode == 13 && !ngModel.$validators.maxlines(ngModel.$modelValue, ngModel.$viewValue)) {
    							elem.val(oldVal);
        					} else if(event.keyCode == 13 && !canAddEnter(ngModel.$viewValue)){
        						elem.val(oldVal);
        					} else if(!ngModel.$validators.limitlength(event.key, ngModel.$viewValue)) {
        						elem.val(oldVal);
        					}
    					}, 1);
    				});
    			}
    			
    			function getTextWidth(text, font) {
        		    var canvas = getTextWidth.canvas || (getTextWidth.canvas = document.createElement("canvas"));
        		    var context = canvas.getContext("2d");
        		    context.font = font;
        		    var metrics = context.measureText(text);
        		    return metrics.width;
        		};

    			function removeKeypress() {
    				elem.off('.maxlines');
    			}

    			scope.$on('$destroy', removeKeypress);
    		}
    	};
    })
    .factory('SignAppletFactory', ['$document', '$window', '$rootScope', 'Language', function($document, $window, $rootScope, Language) {
        var factory = {};

        var documentsToSign = []
        var actualDocumentToSign = 0;

        var runSignature = function (documentToSign) {
            var signConfig = $rootScope.app.configuration.sign_properties;

            factory.setParameter('signature_mode', signConfig['sign-type']);
            factory.setParameter('document_to_sign', documentToSign.url);
            if($rootScope.app.configuration && $rootScope.app.configuration.sign_util_configuration && $rootScope.app.configuration.sign_util_configuration.signature && $rootScope.app.configuration.sign_util_configuration.signature.reason) {
                factory.setParameter('pdf_reason', $rootScope.app.configuration.sign_util_configuration.signature.reason[Language.getActiveColumn()]);
            }else{
                factory.setParameter('pdf_reason', 'Sign');
            }
            factory.setParameter('pdf_location', $rootScope.app.entity.name);

            var w = parseInt(signConfig.width);
            var h = parseInt(signConfig.height);
            var margin = parseInt(signConfig.margin);
            var height = documentToSign.sizes[0];
            var b = parseInt(height) - (h + margin);
            var width = documentToSign.sizes[1];
            var l = parseInt(width) - ((w + margin) * 3);

            var position = documentToSign.position;

            if(position == 2 || position == 5) {
                l = l + w + margin;
            }

            if(position == 3 || position == 6) {
                l = l + ((w + margin) * 2);
            }

            if(position == 4 || position == 5 || position == 6) {
                b = b - (h + margin);
            }

            var pos = l + ' ' + b + ' ' + (l + w) + ' ' + (b + h) + ' ';

            if(documentToSign.signFirstPage) {
                pos += '1';
            } else {
                pos += '-1';
            }

            factory.setParameter('pdf_signature_rectangle', pos);
            if(signConfig.hasRotation) {
                factory.setParameter('pdf_signature_rotation', signConfig.rotation);
            }

            factory.applet.signFromJS(documentToSign.signCertificate.alias);
        }

        var nextSignature = function () {
            actualDocumentToSign++;

            if(actualDocumentToSign < documentsToSign.length) {
                runSignature(documentsToSign[actualDocumentToSign]);
            }
        }

        factory.applet = undefined;

        factory.getApplet = function () {
            factory.applet = $document[0].appletcatcert;
        }

        factory.addSignature = function(signature, url, sizes) {
            documentsToSign.push({
                actionId: signature.id,
                actionOrder: signature.actionOrder,
                document: signature.document,
                position: signature.position,
                signActionType: signature.signActionType,
                signFirstPage: signature.firstPage,
                signState: signature.signState,
                signType: signature.type,
                signCertificate: signature.certificate,
                signPin: signature.pin,
                url: url,
                sizes: sizes
            })
        }

        factory.executeSignatures = function () {
            actualDocumentToSign = 0;

            runSignature(documentsToSign[actualDocumentToSign]);
        }

        factory.onSignOK = function(signature) {
            var eventParams = {
                documentToSign: documentsToSign[actualDocumentToSign],
                signedFile: signature
            };

            $rootScope.$broadcast('appletSignatureOK', eventParams);

            nextSignature();
        }

        factory.onSignError = function (msg) {
            var eventParams = {
                documentToSign: documentsToSign[actualDocumentToSign],
                msg: msg
            }

            $rootScope.$broadcast('appletSignatureError', eventParams);

            nextSignature();
        }

        factory.onSignCancel = function () {
            var eventParams = {
                documentToSign: documentsToSign[actualDocumentToSign],
                msg: 'Cancel'
            }

            $rootScope.$broadcast('appletSignatureError', eventParams);

            nextSignature();
        }

        factory.invokeAppletMethodWithoutParams = function(method) {
            try {
                factory.applet[method]();
            } catch(e) {
                factory.applet[1][method]();
            }
        };

        factory.setParameter = function(name, value) {
            try {
                factory.applet.set(name, value);
            } catch(e) {
                factory.applet[1].set(name, value);
            }
        };

        factory.onMultiSignOK = function (signatures) {

        }

        $window.onSignOK = factory.onSignOK;
        $window.onSignError = factory.onSignError;
        $window.onSignCancel = factory.onSignCancel;
        $window.onMultiSignOK = factory.onMultiSignOK;

        return factory;
    }]);